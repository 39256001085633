<template>
	<div class="layout-topbar shadow-4">
		<div class="layout-topbar-left">
			<router-link class="layout-topbar-logo" to="/">
				<img id="logo" src="/img/logo.png" alt="Radar" style="height: 3.7rem;margin-left: 1rem;">
			</router-link>

			<a class="layout-menu-button shadow-6 p-ripple" @click="onMenuButtonClick($event)" v-ripple>
				<i class="pi pi-chevron-right"></i>
			</a>

			<a class="layout-topbar-mobile-button p-ripple" @click="onTopbarMobileButtonClick($event)" v-ripple>
				<i class="pi pi-ellipsis-v fs-large"></i>
			</a>
		</div>

		<div class="layout-topbar-right" :class="{'layout-topbar-mobile-active': mobileTopbarActive}">
			<div class="layout-topbar-actions-left">
				<AppBreadcrumb></AppBreadcrumb>
			</div>

			<div class="layout-topbar-actions-right">
				<ul class="layout-topbar-items">
					<li class="layout-topbar-item layout-search-item">
						<a class="layout-topbar-action rounded-circle p-ripple" @click="onTopbarItemClick($event, 'search')" v-ripple>
							<i class="pi pi-search fs-large"></i>
						</a>

						<transition name="layout-search-panel" @enter="onEnter">
							<div class="layout-search-panel p-inputgroup" v-show="searchActive" @click="onSearchContainerClick">
								<span class="p-inputgroup-addon"><i class="pi pi-search"></i></span>
								<InputText ref="searchInput" type="text" :placeholder="getSearchLabel" @keydown="onSearchKeydown($event)" />
								<span class="p-inputgroup-addon">
									<Button icon="pi pi-times" class="p-button-rounded p-button-text p-button-plain" @click="changeSearchActive"></Button>
								</span>
							</div>
						</transition>
					</li>
					<li class="layout-topbar-item layout-search-item" style="margin-right: 1rem">
						<Button :class="{ activeLang : $appState.locale == 'hu' }" icon="pi pi-times" class="p-button-rounded p-button-text p-button-plain" @click="changeLanguage('hu')">HU</Button>
						<Button :class="{ activeLang : $appState.locale == 'en' }" icon="pi pi-times" class="p-button-rounded p-button-text p-button-plain" @click="changeLanguage('en')">EN</Button> | 
					</li>

					<li class="layout-topbar-item app" v-show="currentUser()">
						<a class="layout-topbar-action rounded-circle " @click="onTopbarShoppClick()" v-ripple>
							<i class="pi pi-shopping-cart fs-large"></i>
						</a>
					</li>

					<li class="layout-topbar-item" v-show="currentUser()">
						<a class="layout-topbar-action flex flex-row justify-content-center align-items-center px-2 rounded-circle p-ripple" @click="onTopbarItemClick($event, 'profile')" v-ripple>
							<img src="/demo/images/avatar/amyelsner.png" alt="avatar" style="width: 32px; height: 32px;">
						</a>

						<transition name="layout-topbar-action-panel">
							<ul class="layout-topbar-action-panel shadow-6" v-show="activeTopbarItem === 'profile'">
								<li class="layout-topbar-action-item">
									<a class="flex flex-row align-items-center p-ripple" v-ripple>
										<i class="pi pi-cog" :class="{'mr-2': !isRTL, 'ml-2': isRTL}"></i>
										<span>{{ getLabel("Settings") }}</span>
									</a>
								</li>
								<li class="layout-topbar-action-item">
									<a class="flex flex-row align-items-center p-ripple" v-ripple>
										<i class="pi pi-file-o" :class="{'mr-2': !isRTL, 'ml-2': isRTL}"></i>
										<span>{{ getLabel("Terms of Usage") }}</span>
									</a>
								</li>
								<li class="layout-topbar-action-item">
									<a class="flex flex-row align-items-center p-ripple" v-ripple>
										<i class="pi pi-compass" :class="{'mr-2': !isRTL, 'ml-2': isRTL}"></i>
										<span>{{ getLabel("Support") }}</span>
									</a>
								</li>
								<li class="layout-topbar-action-item">
									<a class="flex flex-row align-items-center p-ripple" @click="onLogout()" v-ripple>
										<i class="pi pi-power-off" :class="{'mr-2': !isRTL, 'ml-2': isRTL}"></i>
										<span>{{ getLabel("Logout") }}</span>
									</a>
								</li>
							</ul>
						</transition>
					</li>

				</ul>
			</div>
		</div>
	</div>
</template>

<script>

	import AppBreadcrumb from './AppBreadcrumb.vue';
	
	export default {
		emits: ['menubutton-click', 'topbar-menubutton-click', 'topbaritem-click', 'rightpanel-button-click', 'topbar-mobileactive', 'search-click', 'search-toggle',
		'on-lang-change'],
		data() {
			return {
				searchText: '',
				items: [
					{
			
					}
				],
			}
		},
		props: {
			horizontal: {
				type: Boolean,
				default: false
			},
			topbarMenuActive: {
				type: Boolean,
				default: false
			},
			activeTopbarItem: String,
			mobileTopbarActive: Boolean,
			searchActive: Boolean,
			locale: String
		},
		methods: {
			currentUser() {
				var u = localStorage.getItem("user")
				if (u == null || u.length == 0) 
					return false;
				/*if (u.access.length == 0)
					return false; */
				/*if (u.access.includes('admin')) 
					return true; */
				return true;
			},
			onSearchContainerClick(event) {
				this.$emit("search-click", event);
			},
			changeSearchActive(event) {
				this.$emit('search-toggle', event);
			},
			onMenuButtonClick(event) {
				this.$emit('menubutton-click', event);
			},
			onTopbarMenuButtonClick(event) {
				this.$emit('topbar-menubutton-click', event);
			},
			onTopbarItemClick(event, item) {
				if(item === 'search') {
					this.$emit('search-toggle', event);
				}

				this.$emit('topbaritem-click', {originalEvent: event, item: item});
			},
			onTopbarMobileButtonClick(event) {
				this.$emit('topbar-mobileactive', event);
			},
			onRightPanelButtonClick(event) {
				this.$emit('rightpanel-button-click', event);
			},
			onSearchKeydown(event) { 
				if (event.keyCode == 13) {
					this.$emit('search-toggle', event);
				}
			},
			onEnter() {
				if (this.$refs.searchInput) {
					this.$refs.searchInput.$el.focus();
				}
			},
			onLogout() {
				this.$router.push("/logout");
			},
			onTopbarShoppClick() {
				this.$router.push("/shopping-cart");
			},
			changeLanguage(lang) {
				this.$appState.locale = lang
			},
			getLabel(label) {
				return this.$t("message."+label, {}, { locale: this.$appState.locale });
			}			
		},
		computed: {
			topbarItemsClass() {
				return ['topbar-items animated fadeInDown', {
					'topbar-items-visible': this.topbarMenuActive
				}];
			},
			isRTL() {
				return this.$appState.RTL;
			},
			getSearchLabel() {
				return this.$t("message.menu.Search", {}, { locale: this.$appState.locale });
			},
		},
		components: {
			'AppBreadcrumb': AppBreadcrumb
		},
	}
</script>

<style scoped>
	.layout-topbar .layout-topbar-left .layout-topbar-logo {
		justify-content: start;
	}
	.activeLang {
		color: rgba(255, 255, 255, 1)!important;
	}
</style>
