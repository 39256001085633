import authHeader from './AuthHeader';

export default class BasketService {

    async addBasket(info) {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(info)
        };
        // console.log ("INFO", info)
        const response = await fetch("/auth/addBasket/"+info.yearid.code+"/"+info.agglevelid.code+"/"+info.infoid, requestOptions);
        const data = await response.json();
        return  (response.status == 200);
    }

    deleteBasket(infoid) {

    }

    updateBasket(infoid) {

    }

    getBaskets() {
        return fetch('/auth/basketList', { headers: authHeader() } ).then(res => res.json());
    }

    getOrders() {
        return fetch('/auth/orderList', { headers: authHeader() } ).then(res => res.json());
    }

    getOrderDownload (orderid) {
        return fetch('/auth/getOrderDownload/'+orderid, { headers: authHeader() } ).then(res => res.json());
    }


    getBasketDelete (productid) {
        return fetch('/auth/getBasketDelete/'+productid, { headers: authHeader() } ).then(res => res.json());
    }

    getBasketAllDelete () {
        return fetch('/auth/getBasketAllDelete/', { headers: authHeader() } ).then(res => res.json());
    }

    getOrderDelete (orderid) {
        return fetch('/auth/getOrderDelete/'+orderid, { headers: authHeader() } ).then(res => res.json());
    }    

    async getOrderAdd(info) {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(info)
        };
        const response = await fetch("/auth/addOrder/"+info.yearid+"/"+info.agglevelid+"/"+info.conceptid, requestOptions);
        const data = await response.json();
        return  (data);
    }

    async getOrderAllAdd(info) {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(info)
        };
        const response = await fetch("/auth/addAllOrder/"+info, requestOptions);
        const data = await response.json();
        return  (data);
    }

    /*
    getBasketNum() {
        return fetch('/auth/getBasketNum', { headers: authHeader() } )
        .then((res) => {
            if (res.ok) {
                return res.json();
            }
            throw new Error('Something went wrong', res.ok);            
        })
        .then ((resJSON) => {
            console.log ("RJ", resJSON.num)
            if (resJSON.num) {
                return resJSON.num
            } else {
                return 0;
            }
        })
        .catch ((error) => {
            console.log(error)
            return 0;
        })
    }
    */

    getBasketNum() {
        return fetch('/auth/getBasketNum', { headers: authHeader() } ).then (res => res.json()).then (res => res.num);
    }

    getOrderNum() {
        return fetch('/auth/getOrderNum', { headers: authHeader() } ).then (res => res.json()).then (res => res.num);
    }
  
}
    