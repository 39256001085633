<template>
	<ul v-if="items" role="menu">
        <template v-for="(item,i) of items">
            <li v-if="visible(item) && !item.separator && visibleMenuCheck(item)" :key="item.label || i" :class="[{'layout-root-menuitem': root, 'active-menuitem': activeIndex === i && !item.disabled}]" role="menuitem">
				<div v-if="root">
					<span class="layout-menuitem-text">{{ getLabel(item.label) }}</span>
				</div>
				<div v-if="item.to && item.infoid" style="position: relative;">
					<div style="position: relative;">
						<div style="position: relative;">
							<router-link v-if="item.to && item.infoid" :to="item.to" :style="item.style" style="padding-left: 5px"
								:target="item.target" active-class="active-route">
								<table>
									<tr>
										<td>
											<table>
												<tr>
													<td>
														<i v-if="!item.enabledchecked" :class="['layout-menuitem-icon', item.icon]"></i>
														<Checkbox v-if="item.enabledchecked" :id="item.infoid" v-model="item.checkvisible" class="specialBox" :binary="true" @click="visibleRow(item.infoid, item.checkvisible)" />
													</td>
												</tr>
												<tr>
													<td style="padding-left: 3px; padding-top: 5px;">
														<i v-if="!item.data" class="pi pi-plus-circle"></i>
														<i v-if="item.data" class="pi pi-minus-circle"></i>
													</td>
												</tr>
											</table>
										</td>
										<td>
											<span class="layout-menuitem-text">{{ item.label }}</span>
										</td>
										<td>
											<table style="margin-left: auto">
												<tr  v-if="item.enableddeleted">
													<td><font-awesome-icon icon="trash-can" style="color: red; padding-left:3px"  @click="deleteRow(item.infoid)" /></td>
												</tr>
												<tr v-if="item.infoid && userLoggedIn()" >
													<td><font-awesome-icon icon="cart-plus" class="defaultCart" :class="{ basket: item.basketchecked }" @click="addCart(item)" /></td>
												</tr>
											</table>
										</td>
									</tr>
								</table>
							</router-link>
						</div>
						<div v-if="item.to && item.infoid">
							<Dropdown style="width: 50%;" @change="onChangeYear($event, item.infoid, item)" v-model="item.yearid" :options="dropdownYearsValuesInfo(item.infovalue)" name="drop" optionLabel="name" placeholder="évek" />
							<Dropdown style="width: 50%;" @change="onChangeAggLevel($event, item.infoid, item)" v-model="item.agglevelid" :options="dropdownAggLevelValuesInfo(item.infovalue)" name="drop" optionLabel="name" placeholder="szintek" />
						</div>					
					</div>
					<div v-if="item.load" style="position: absolute; top:0; left: 0; width: 100%; height: 100%; background: rgba(76, 175, 80, 0.3);">
						<div class="splash-screen">
							<div class="splash-loader-container">
								<svg class="splash-loader" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
									<circle class="splash-path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
								</svg>
							</div>
						</div>
					</div>
				</div>


                <router-link v-if="item.to && !item.infoid" :to="item.to" :style="item.style" :class="[item.class, 'p-ripple', {'p-disabled': item.disabled}]" 
					:target="item.target" active-class="active-route" exact
					@mouseenter="onMenuItemMouseEnter(i)" @mouseleave="onMenuItemMouseLeave" @click="onMenuItemClick($event,item,i)" v-ripple>
                    <i :class="['layout-menuitem-icon', item.icon]"></i>
                    <span class="layout-menuitem-text">{{ getLabel(item.label) }}</span>
					<span class="p-badge p-component p-badge-no-gutter" :class="item.badgeStyleClass" :style="item.badgeStyle" v-if="(item.badge || item.type) && !root">{{ item.badge }}</span>
					<i v-if="item.items" class="pi pi-fw pi-angle-down layout-submenu-toggler"></i>
                </router-link>

                <a v-if="!item.to" :href="item.url||'#'" :style="item.style" :class="[item.class, 'p-ripple', {'p-disabled': item.disabled}]" :target="item.target"
					@click="onMenuItemClick($event,item,i)" @mouseenter="onMenuItemMouseEnter(i)" @mouseleave="onMenuItemMouseLeave" v-ripple>
                    <i :class="['layout-menuitem-icon', item.icon]"></i>
                    <span class="layout-menuitem-text">{{ getLabel(item.label) }}</span>
					<span class="p-badge p-component p-badge-no-gutter" :class="item.badgeStyleClass" v-if="item.badge && !root">{{item.badge}}</span>
                    <i v-if="item.items" class="pi pi-fw pi-angle-down layout-submenu-toggler"></i>

                </a>


				<span class="layout-menuitem-tooltip p-tooltip">
					<span class="layout-menuitem-tooltip-arrow p-tooltip-arrow"></span>
					<span class="layout-menuitem-tooltip-text p-tooltip-text">{{ $t("message.menu."+item.label, {}, { locale: $appState.locale }) }}</span>
				</span>
                <transition name="layout-menu">
                    <appsubmenu v-show="item.items && (root && (!isSlim() || (isSlim() && (mobileMenuActive || activeIndex !== null))) ? true : activeIndex === i)" :items="visible(item) && item.items" @menuitem-click="$emit('menuitem-click', $event)" :menuMode="menuMode"
                            :menuActive="menuActive" :parentMenuItemActive="activeIndex === i"></appsubmenu>
                </transition>
            </li>
            <li class="p-menu-separator" :style="item.style" v-if="visible(item) && item.separator" :key="'separator' + i" role="separator"></li>
        </template>
	</ul>
</template>
<script>
import EventBus from './event-bus';
import AuthService from './service/AuthService'
import BasketService from './service/BasketService'
import authHeader from './service/AuthHeader';

export default {
	name: 'appsubmenu',
	emits: ['menuitem-click', 'root-menuitem-click'],
	props: {
		items: Array,
		fullmenu: Array,
		root: {
			type: Boolean,
			default: false
		},
		menuActive: {
			type: Boolean,
			default: true
		},
		parentMenuItemActive: {
			type: Boolean,
			default: false
		},
		menuMode: String,
		mobileMenuActive: Boolean,
		isSlimOrHorItemClick: Boolean,
		locale: String,
	},
	data() {
		return {
			activeIndex : null,
			hoverMenuActive: false
		}
	},
	basketService: null,
	created() {
		// console.log ("FM", this.fullmenu);
		this.basketService = new BasketService();
		if (! this.checkUsersToken()) {
			let user = JSON.parse(localStorage.getItem('user'));
			if (user && user.token) {
				localStorage.removeItem('user');
			}
			this.$router.push("/login")
		}
		this.getItemsBadge(this.items);
	},	
	mounted() {
		EventBus.on('reset-active-index', () => {
			if(this.isHorizontalOrSlim() && !this.isMobile()) {
				this.activeIndex = null;
			}
		});
	},
	methods: {
		hello() {
			alert ("HELLO");
			// console.log ("HELLO");
		},
		onMenuItemClick(event, item, index) {
			// // console.log ("C", item.command, "E",event, "I", item, "Index", index)
			// // console.log ("EP", event.preventDefault)
			// // console.log("ET", event.currentTarget, event.target.id)
			if (item.disabled) {
				event.preventDefault();
				return;
			}


			//execute command
			if (item.command) {
                item.command({originalEvent: event, item: item});
                event.preventDefault();
			}

            if (item.items) {
                event.preventDefault();
            }
			else {
                if(this.isHorizontalOrSlim()){
                    this.hoverMenuActive = false;
                }

				if(this.menuMode !== 'static') {
					const ink = this.getInk(event.currentTarget);
					if (ink) {
						this.removeClass(ink, 'p-ink-active');
					}
				}
			}

			if (this.root) {
                this.hoverMenuActive = !this.hoverMenuActive;

				this.$emit('root-menuitem-click', {
					originalEvent: event,
					isSameIndex: index === this.activeIndex
				});
			}

            if (item.items) {
                this.activeIndex = index === this.activeIndex ? null : index;
            }

			this.$emit('menuitem-click', {
				originalEvent: event,
				item: item
			});
		},
		onMenuItemMouseEnter(index) {
			if(this.isSlimOrHorItemClick) {
				this.hoverMenuActive = true;
			}

			if(this.root && this.hoverMenuActive && this.isHorizontalOrSlim() && !this.isMobile()) {
				this.activeIndex =  index;
			}
        },
		onMenuItemMouseLeave() {
			this.hoverMenuActive = false;
		},
		isHorizontalOrSlim() {
			return (this.menuMode === 'horizontal' || this.menuMode === 'slim');
		},
		isMobile() {
			return window.innerWidth <= 640;
		},
        visible(item) {
            return (typeof item.visible === 'function' ? item.visible() : item.visible !== false);
        },
		getInk(el) {
			for (let i = 0; i < el.children.length; i++) {
				if (typeof el.children[i].className === 'string' && el.children[i].className.indexOf('p-ink') !== -1) {
					return el.children[i];
				}
			}
			return null;
		},
		removeClass(element, className) {
			if (element.classList)
				element.classList.remove(className);
			else
				element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
		},
		isSlim() {
			return this.menuMode === 'slim';
		},
		userLoggedIn() {
			return !(localStorage.getItem("user") === null || localStorage.getItem("user") === undefined)
		},
		visibleMenuCheck(item) {
			var userLoggedIn = !(localStorage.getItem("user") === null || localStorage.getItem("user") === undefined)
			// // console.log (localStorage.getItem("user"))
			// AuthService.loggedIn();
			if (item.status == 'signin' && userLoggedIn) {
				return false;
			}
			if (item.status == 'signout' && !userLoggedIn) {
				return false;
			}
			
			if (item.group == 'users' && userLoggedIn) {
				return true;
			}
			
			// alert(AuthService.getTokenInfo())
//			// console.log(AuthService.getTokenInfo())
			if (item.group == 'everybody') {
				return true;
			}
			// // console.log (item.group, AuthService.getIsAdminAccess('admin'), item.group == 'admin')
			if (item.group == 'admin' && AuthService.getIsAdminAccess('admin')) {
				return true;
			}
			return false;
			// return false;
		},
		dropdownAggValuesInfo (info) {
			return [{
                    name: 'Országos',
                    code: 1
                },
                {
                    name: 'Megyei',
                    code: 2
                },
                {
                    name: 'Járás',
                    code: 3
                },
                {
                    name: 'Település',
                    code: 4
                }
            ];
		},
		getLabel(label) {
			var info
			try {
				// // console.log ("T",  this.$t("message.menu."+label))
				info = this.$t("message.menu."+label, {}, { locale: this.$appState.locale } )
				if (info == "message.menu."+label) {
					info = label;
				}
			}
			catch(err) {
				info = label;
			}
			return info;				
		},
		deleteRow(infoidremove) {
			// console.log("DR START", infoidremove, this.$myMapMenuDelete.id);
			this.$myMapMenuDelete.id = infoidremove;
			// console.log ("DR END", this.$myMapMenuDelete.id);
		},
		visibleRow(infoid, checked) {
			// console.log("VL START", infoid, checked);
			this.$myMapView.id = infoid + ";" + checked;
			// console.log ("VL END", this.$myMapView.id);
		},
		dropdownYearsValuesInfo(infovalue) {
            var info = [];
			if (infovalue.length > 0) {
				var infoparse = JSON.parse(infovalue)
				var list = infoparse.years;
				if (list != undefined && list.length > 0) {
					var myArray = list.split(",");
					for (var i = 0; i < myArray.length; i++) {
						var name = myArray[i]
						name=name.replace(/\s/g, '')
						info.push({
							name: name,
							code: name
						})
					}
					return info;
				} else {
					return [];
				}
			} else {
				return [];
			}
		},
		dropdownAggLevelValuesInfo(infovalue) {
            var info = [];
			if (infovalue.length > 0) {
				var infoparse = JSON.parse(infovalue)
				var list = infoparse.agglevel;
				if (list != undefined && list.length > 0) {
					var myArray = list.split("|");
					for (var i = 0; i < myArray.length; i++) {
						var name = myArray[i].split(" (")[0]
						var code = myArray[i].split("(")[1].split(")")[0]
						var value = myArray[i].split(" [")[1].split("]")[0] + 0
						info.push({
							name: name,
							code: code,
							value: value
						})
					}
					info.sort((a, b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0))
					return info;
				} else {
					return [];
				}
			} else {
				return [];
			}

		},
		onChangeYear(event, infoid, info) {
			// console.log("YEAR CHANGE:", infoid, event)
			this.$myMapChange.id = infoid+ "|YEAR|" +event.value.code
			info.basketchecked = false
		},
		onChangeAggLevel(event, infoid, info) {
			// console.log("AGGLEVEL CHANGE:", infoid, event)
			this.$myMapChange.id = infoid + "|AGGLEVEL|" + event.value.code
			info.basketchecked = false
		},
		addCart(info) {
			this.basketService.addBasket(info).then (res => {

				// this.getAllItems (this.allmenu, 0);
				info.basketchecked = res;
				this.$myOrder.basket = true;
				// console.log ("addCart End")
			})
		},
		async checkUsersToken() {
			try{
				let user = JSON.parse(localStorage.getItem('user'));
				if (user && user.token) {
					let response = await fetch ('/auth/refreshToken', { headers: { Authorization: 'Bearer ' + user.token } } )
					return await (response.status == 200);
				} else {
					return false;
				}
			} catch(err){
				console.error(err);
				return false;
			}
		},
		getItemsBadge (items) {
			if (items) {
				if (Array.isArray(items)) {
					items.forEach(
						item => {
							this.getItemBadge(item);	
						});
				}
			}
		},
		getAllItems (items, level) {
			console.log ("GetAllItems-S")
			if (items && Array.isArray(items) && level < 100) {
				items.forEach( item => {
					if (item.items) {
						this.getAllItem (item.items, level+1)
					}
					this.getItemBadge(item)
				})
			}
			// console.log ("GetAllItems-E")
		},
		getItemBadge(item) {
			if (item.type) {
				if (item.type == 'shopping') {
					this.basketService.getBasketNum().then (res => {
						// console.log ("HERE-1", res)
						item.badge = res;
					});
				} else {
					if (item.type == 'order' ) {
						this.basketService.getOrderNum().then (res => {
								// console.log ("HERE-2", res)
								item.badge = res;
						});						
					}
				}
			}
		},
	},
}


async function getBasketNumHere() {
	let response = await fetch('/auth/getBasketNum', { headers: authHeader() } );
    return await response.json();
}

</script>

<style scoped>
	.SpecialMenu {
		display: flex;
		align-items: center;
		padding: 0.75rem;
		border-radius: 4px;
		cursor: pointer;
		user-select: none;
		transition: background-color 0.2s, color 0.2s;
	}

	.defaultCart {
		color: white;
	}

	.basket {
		color: yellow;
	}

</style>
