import axios from 'axios';
import authHeader from './AuthHeader';

const API_URL = '/backend/';

class AuthService {
  login(user) {
    return axios
      .post(API_URL + 'login', {
        username: user.username,
        password: user.password,
      })
      .then(response => {
        if (response.data.token) {
          // console.log("STORE", JSON.stringify(response.data))
          localStorage.setItem("user", JSON.stringify(response.data));
          // console.log("LOCAL", localStorage.getItem("user"))
        }
        return response.data;
      });
  }

  logout() {
    // console.log("LOCAL START", localStorage.getItem("user"))
    localStorage.removeItem('user');
    // console.log("LOCAL END", localStorage.getItem("user"))
  }

  loggedIn() {
    // console.log ("UT:", this.checkUsersToken())
    if (this.checkUsersToken()) {
      return (localStorage.getItem("user"));
    } else {
      return null;
    }
    
  }

  register(user) {
    return axios.post(API_URL + 'signup', {
      username: user.username,
      email: user.email,
      password: user.password
    });
  }

  getTokenInfo() {
      var u = localStorage.getItem("user")
      if (! u) {
        return "";
      }
      var token = JSON.parse(u).token
      if (! token) {
        return "";
      }
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      return JSON.parse(jsonPayload);
     // return token;
  }

  getIsAdminAccess(access) {
    var u = localStorage.getItem("user")
    if (! u) {
      return false;
    }
    var uaccess = JSON.parse(u).access;
    if (! uaccess) {
      return false;
    }
    var back = false
    uaccess.forEach(function callback(value) {
      if (access == value) {
        back = true
      }
    });
    return back;
  }

  async checkUsersToken() {
      const response = await fetch ('/auth/refreshToken', { headers: authHeader() } )
      if (response.status != 200) {
        localStorage.removeItem('user');
      }
      return response.status == 200
  }

}

export default new AuthService();