<template>
	<div class="layout-breadcrumb-container flex justify-content-between align-items-center shadow-1">
		<Breadcrumb :home="home" :model="items" class="layout-breadcrumb pl-4 py-2">
			<template #item="{item}">
				<a :href="item.url">{{ item.label }}</a>
			</template>
		</Breadcrumb>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				home: {icon: 'pi pi-home', to: '/'},
				items: []
			}
		},
		watch: {
			$route() {
				this.watchRouter();
			},
			'$appState.locale'() {
				this.watchRouter();
			}
		},
		created() {
			this.watchRouter();
		},
		methods: {
			watchRouter() {
				if(this.$router.currentRoute.value.meta.breadcrumb) {
					this.items = [];
					const bc = this.$router.currentRoute.value.meta.breadcrumb[0];
					for(let pro in bc) {
						this.items.push({label: 
								this.getLabelInfo(bc[pro]) 
							}
						);
					}
				}
			},
			getLabelInfo (label) {
				var info
				try {
					info = this.$t("message.menu."+label, {}, { locale: this.$appState.locale } )
					if (info == "message.menu."+label) {
						info = label;
					}
				}
				catch(err) {
					info = label;
				}
				return info;
			}
		},
	}
</script>

<style scoped>

</style>
