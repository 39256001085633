<template>
	<div :class="layoutContainerClass" @click="onDocumentClick">

		<AppTopBar :locale="locale" @onLangChange="on-lang-change" :horizontal="menuMode==='horizontal'" :topbarMenuActive="topbarMenuActive" :activeTopbarItem="activeTopbarItem" :mobileTopbarActive="mobileTopbarActive" @topbar-mobileactive="onTopbarMobileButtonClick"
			@menubutton-click="onMenuButtonClick" @topbar-menubutton-click="onTopbarMenuButtonClick" @topbaritem-click="onTopbarItemClick" @rightpanel-button-click="onRightPanelButtonClick"
			:searchActive="searchActive" @search-toggle="onSearchToggle" @search-click="onSearchClick" @search-hide="onSearchHide"></AppTopBar>

			<div class="menu-wrapper">
				<div class="layout-menu-container" @click="onMenuClick">
						<AppMenu :locale="locale" :model="menu" :allmenu="menu" :menuMode="menuMode" :active="menuActive" :mobileMenuActive="mobileMenuActive" :isSlimOrHorItemClick="isSlimOrHorItemClick" @menuitem-click="onMenuItemClick" @root-menuitem-click="onRootMenuItemClick"></AppMenu>
				</div>
			</div>

		<div class="layout-main">

			<!-- <AppBreadcrumb></AppBreadcrumb> --> 

			<div class="layout-content">
				<router-view :model="menu" :items="menu" />
			</div>

		</div>

		<div v-if="mobileMenuActive" class="layout-mask modal-in"></div>
	</div>
</template>

<script>
import AppTopBar from './AppTopbar.vue';
// import AppInlineMenu from './AppInlineMenu.vue';
// import AppRightPanel from './AppRightPanel.vue';
import AppMenu from './AppMenu.vue';
// import AppConfig from './AppConfig.vue';
// import AppFooter from './AppFooter.vue';
import AppBreadcrumb from './AppBreadcrumb.vue';
import EventBus from './event-bus';
// import { usePrimeVue } from "primevue/config";
// const primevue = usePrimeVue();
// import { useI18n } from 'vue-i18n'
import BasketService from './service/BasketService'

// const { t } = useI18n()

export default {
	emits: ['layout-mode-change', 'menu-theme', 'menuTheme', 'topbar-theme', 'topbarTheme', 'layoutModeChange', 'on-lang-change'],
	props: {
		topbarTheme: String,
		menuTheme: String,
		layoutMode: String,
		locale: String
	},
	basketService: null,	
    data() {
        return {
			// mapSearch: { name: "alma", value: "korte" },
			d_topbarTheme: this.topbarTheme,
			d_menuTheme: this.menuTheme,
			d_layoutMode: this.layoutMode,
			mobileTopbarActive: false,
			mobileMenuActive: false,
			search: false,
			searchClick: false,
			searchActive: false,
			menuMode: 'static',
			inlineMenuClick: false,
			inlineMenuPosition: 'bottom',
			inlineMenuTopActive: false,
			inlineMenuBottomActive: false,
			overlayMenuActive: false,
			rotateMenuButton: false,
			topbarMenuActive: false,
			activeTopbarItem: null,
			isSlimOrHorItemClick: false,
			darkMenu: true,
			theme: 'gray',
			themes: [
				{name: 'indigo', color: '#2f8ee5'},
				{name: 'pink', color: '#E91E63'},
				{name: 'purple', color: '#9C27B0'},
				{name: 'deeppurple', color: '#673AB7'},
				{name: 'blue', color: '#2196F3'},
				{name: 'lightblue', color: '#03A9F4'},
				{name: 'cyan', color: '#00BCD4'},
				{name: 'teal', color: '#009688'},
				{name: 'green', color: '#4CAF50'},
				{name: 'lightgreen', color: '#8BC34A'},
				{name: 'lime', color: '#CDDC39'},
				{name: 'yellow', color: '#FFEB3B'},
				{name: 'amber', color: '#FFC107'},
				{name: 'orange', color: '#FF9800'},
				{name: 'deeporange', color: '#FF5722'},
				{name: 'brown', color: '#795548'},
				{name: 'bluegrey', color: '#607D8B'},
				{name: "black", color: '#000000'},
				{name: "gray", color: '#121212'}
			],
			menuThemes: [
				{name: 'light', color: '#FDFEFF'},
				{name: 'dark', color: '#434B54'},
				{name: 'indigo', color: '#1A237E'},
				{name: 'bluegrey', color: '#37474F'},
				{name: 'brown', color: '#4E342E'},
				{name: 'cyan', color: '#006064'},
				{name: 'green', color: '#2E7D32'},
				{name: 'deeppurple', color: '#4527A0'},
				{name: 'deeporange', color: '#BF360C'},
				{name: 'pink', color: '#880E4F'},
				{name: 'purple', color: '#6A1B9A'},
				{name: 'teal', color: '#00695C'}
			],

			topbarThemes: [
				{name: 'lightblue', color: '#2E88FF'},
				{name: 'dark', color: '#363636'},
				{name: 'white', color: '#FDFEFF'},
				{name: 'blue', color: '#1565C0'},
				{name: 'deeppurple', color: '#4527A0'},
				{name: 'purple', color: '#6A1B9A'},
				{name: 'pink', color: '#AD1457'},
				{name: 'cyan', color: '#0097A7'},
				{name: 'teal', color: '#00796B'},
				{name: 'green', color: '#43A047'},
				{name: 'lightgreen', color: '#689F38'},
				{name: 'lime', color: '#AFB42B'},
				{name: 'yellow', color: '#FBC02D'},
				{name: 'amber', color: '#FFA000'},
				{name: 'orange', color: '#FB8C00'},
				{name: 'deeporange', color: '#D84315'},
				{name: 'brown', color: '#5D4037'},
				{name: 'grey', color: '#616161'},
				{name: 'bluegrey', color: '#546E7A'},
				{name: 'indigo', color: '#3F51B5'}
			],
			rightPanelActive: false,
			menuActive: true,
            menu: [
				{
					label: 'Dashboard',
					//  this.$appState.locale+primevue.config.locale.en.dashboard,
					icon: 'pi pi-fw pi-home',
					group: 'everybody',
					items: [
						{
							label: 'Search', 
							icon: 'pi pi-fw pi-search', 
							to: '/', group: 'everybody'
						},
						{
							label: 'Map', 
							icon: 'pi pi-fw pi-map', 
							idinfo: 'map', 
							to: '/map', 
							group: 'everybody',
							items: [
								/*
								{ label: "Test", icon: 'pi pi-fw pi-briefcase', to: '/map-special', group: 'everybody', 
									infoid: 201234, 
									yearid: 2012, 
									agglevelid: 1234,
									infovalue: "",
									checkvisible: true,
									enableddeleted: true,
									enabledchecked: false,
								},
								{ label: "Bér", icon: 'pi pi-fw pi-briefcase', to: '/map-special', group: 'everybody', 
									infoid: 122469, 
									yearid: 2018, 
									agglevelid: 55855,
									infovalue: "",
									checkvisible: true,
									enableddeleted: true,
									enabledchecked: true,
								}
								*/
							]
						},
						{
							label: 'Other', 
							icon: 'pi pi-fw pi-palette', 
							group: 'everybody',
							items: [						
								{
									label: 'City Logos',
									icon: 'pi pi-fw pi-map-marker', 
									to: '/map-city-logos', 
									group: 'everybody'
								},
							]
						}
					]
				},
				{
					label: 'User',
					group: 'everybody',
					icon: 'pi pi-fw pi-home',
					items: [
						{label: 'Login', icon: 'pi pi-sign-in', to: '/login', status: 'signin', group: 'everybody'},
						{label: 'Logout', icon: 'pi pi-sign-out', to: '/logout', status: 'signout', group: 'everybody'}
					]
				},
				{
					label: 'Shop',
					group: 'users',
					icon: 'pi pi-fw pi-shopping-cart',
					items: [
						{label: 'Shopping Cart', icon: 'pi pi-shopping-cart', to: '/shopping-cart', group: 'users', badge: 1, badgeStyleClass: 'p-badge-info', type: 'shopping' },
						{label: 'My orders', icon: 'pi pi-shopping-bag', to: '/my-orders', group: 'users', badge: 1, badgeStyleClass: 'p-badge-success', type: 'order' },
					]
				},
				{
					label: 'Administration',
					group: 'admin',
					icon: 'pi pi-fw pi-admin',
					items: [
						{ 
							label: 'Users', 
							icon: 'pi pi-fw pi-home', 
							group: 'admin', 
							items:[
								{ 
									label: 'Manage', 
									icon: 'pi pi-fw pi-check-square', 
									to: '/users/manage', 
									badge: 1, 
									badgeStyleClass: 'p-badge-danger',
									group: 'admin',
								},
								{
									label: "Groups", 
									icon: "pi pi-fw pi-bookmark", 
									to: "/users/groups",
									group: 'admin',
								},
								{
									label: 'Roles', 
									icon: 'pi pi-fw pi-exclamation-circle', 
									to: '/users/roles',
									group: 'admin',
								},
							]
						},
					]
				}
            ]
        }
    },
    watch: {
        $route() {
			if (this.$route.name == "search" || this.$route.name == "undefined") {
				this.menuActive = true;
			} else {
				this.menuActive = true;
			}
			this.menuActive = true;
			// this.isStatic() && !this.isMobile();
            this.$toast.removeAllGroups();
        },
		topbarTheme(newValue) {
			this.d_topbarTheme = newValue;
		},
		menuTheme(newValue) {
			this.d_menuTheme = newValue;
		},
		layoutMode(newValue) {
			this.d_layoutMode = newValue;
		},
		'$myOrder.basket' (value) {
			if (value) {
				this.$myOrder.basket = false;
				this.itemsBadgeReload(this.menu, 0)
			}
		},
		'$myMapMenu.info'(value) {
			if (value.length > 0) {
				// console.log ("myMapMenu.info", value)
				var infoparse = JSON.parse(value)
				var labelnames = infoparse.concept.split(" | ")
				var polygonsMenu = this.searchMenuPolygon();
				if (! this.checkPolygons(polygonsMenu, infoparse.conceptid)) {
					var labelname = labelnames[0]; // + " - " + infoparse.Agglevel2.name + " - " + infoparse.Years2.name
					if (polygonsMenu.items === undefined || polygonsMenu.items == null) {
						polygonsMenu.items = [];
					}
					polygonsMenu.items.push ({
						label: labelname, icon: 'pi pi-fw pi-briefcase', to: '/map-special', group: 'everybody', infoid: infoparse.conceptid, 
						yearid: infoparse.years2, 
						agglevelid: infoparse.agglevel2,
						infovalue: value,
						checkvisible: false,
						enableddeleted: true,
						enabledchecked: true,
						basketchecked: false,
					});
				}
			}
		},
		'$myMapMenuDelete.id'(value) {
			// console.log ("DELETE ROW:", value)
			if (value > 0 && value !== undefined) {
				this.$myMapDelete.id = value;
				// console.log ("myMapMenuDelete.id", value)
				var polygonsMenu = this.searchMenu(this.menu, 'map');
				// console.log ("polygonsMenu", polygonsMenu)
				polygonsMenu.items = polygonsMenu.items.filter (function(item) {
					return item.infoid != value
				});
				// console.log ("polygonsMenu", polygonsMenu)
				if (polygonsMenu.items.length < 1) {
					polygonsMenu.items = null;
				}
				// 1. Search element ITEMS.ITEM -> Ahol infoid == value ott filter
				// 2. Ha nincsen ITEMS-ben elem a végén, akkor törölni kell a ITEMS részt is!
				this.$myMapMenuDelete.id = 0;
			}
		}
    },
	created() {
		this.basketService = new BasketService();
	},
    methods: {
		itemsBadgeReload(items, level) {
			if (items && Array.isArray(items) && level < 100) {
				items.forEach( item => {
					if (item.items) {
						this.itemsBadgeReload (item.items, level+1)
					}
					if (item.type) {
						if (item.type == 'shopping') {
							this.basketService.getBasketNum().then (res => {
								item.badge = res;
							});
						} else {
							if (item.type == 'order' ) {
								this.basketService.getOrderNum().then (res => {
									item.badge = res;
								});						
							}
						}							
					}
				})
			}
		},

		onDocumentClick() {
			if (!this.searchClick && this.searchActive) {
                this.onSearchHide();
            }

			if(!this.topbarItemClick) {
				this.activeTopbarItem = null;
				this.topbarMenuActive = false;
			}

			if(!this.menuClick) {
				if(this.isHorizontal() || this.isSlim()) {
					this.menuActive = false;
					this.isSlimOrHorItemClick = false;
					EventBus.emit('reset-active-index');
				}

				if (this.isOverlay()) {
                    this.menuActive = false;
                }

				this.hideOverlayMenu();
				this.unblockBodyScroll();
			}

			if(!this.rightPanelClick) {
				this.rightPanelActive = false;
			}

			if(!this.inlineMenuClick) {
				this.inlineMenuTopActive = false;
				this.inlineMenuBottomActive = false;
			}

			this.topbarItemClick = false;
			this.menuClick = false;
			this.rightPanelClick = false;
			this.searchClick = false;
			this.inlineMenuClick = false;
        },
		onSearchToggle() {
            this.searchActive = !this.searchActive;
            this.searchClick = true;
        },
		onSearchClick() {
            this.searchClick = true;
        },
        onSearchHide() {
            this.searchActive = false;
            this.searchClick = false;
        },
		isHorizontal() {
			return this.menuMode === 'horizontal';
		},
		isSlim() {
			return this.menuMode === 'slim';
		},
		isOverlay() {
			return this.menuMode === 'overlay';
		},
		isStatic() {
			return this.menuMode === 'static';
		},
		isDesktop() {
			return window.innerWidth > 991;
		},
		isMobile() {
			return window.innerWidth <= 991;
		},
		hideOverlayMenu() {
			this.rotateMenuButton = false;
			this.overlayMenuActive = false;
			this.mobileMenuActive = false;
		},
		onMenuButtonClick(event){
			this.menuClick = true;
			this.menuActive = !this.menuActive;
			this.topbarMenuActive = false;
			this.topbarRightClick = true;
			this.rotateMenuButton = !this.rotateMenuButton;
			this.topbarMenuActive = false;

			if(!this.isDesktop()) {
				this.mobileMenuActive = !this.mobileMenuActive;
				if (this.mobileMenuActive) {
					this.blockBodyScroll();
				} else {
					this.unblockBodyScroll();
				}
			}

			event.preventDefault();
		},
		onTopbarMenuButtonClick(event) {
			this.topbarItemClick = true;
			this.topbarMenuActive = !this.topbarMenuActive;
			this.hideOverlayMenu();
			event.preventDefault();
		},
		onTopbarItemClick(event){
			this.topbarItemClick = true;

			if(this.activeTopbarItem === event.item)
				this.activeTopbarItem = null;
			else
				this.activeTopbarItem = event.item;

			event.originalEvent.preventDefault();
		},
		onTopbarMobileButtonClick(event) {
			this.mobileTopbarActive = !this.mobileTopbarActive;
			event.preventDefault();
		},
		onRightPanelButtonClick(event){
			this.rightPanelClick = true;
			this.rightPanelActive = !this.rightPanelActive;

			event.preventDefault();
		},
		onRightPanelClick(){
			this.rightPanelClick = true;
		},
		onHideClick(expanded){
			this.rightPanelActive = expanded;
		},
		onMenuClick() {
			this.menuClick = true;
		},
		onRootMenuItemClick(event) {
            if(event.isSameIndex) {
                this.isSlimOrHorItemClick = false;
            }
            else {
                this.isSlimOrHorItemClick = true;
            }
			this.menuActive = !this.menuActive;
		},
		onMenuItemClick(event) {
			if(!event.item.items) {
                this.isSlimOrHorItemClick = false;
				this.hideOverlayMenu();
				EventBus.emit('reset-active-index');
			}

			if(!event.item.items && (this.isHorizontal() || this.isSlim())) {
				this.menuActive = false;
			}
		},
		onMenuModeChange(menuMode) {
			this.menuMode = menuMode;
			this.overlayMenuActive = false;
			
			if(menuMode === 'static') {
				this.menuActive = true;
			}

			if(menuMode === 'horizontal') {
				this.inlineMenuPosition = 'bottom';
			}
		},
		onLayoutModeChange(menuColor) {
			this.$emit('layout-mode-change', menuColor);

			const layoutLink = document.getElementById('layout-css');
			const layoutHref = 'layout/css/layout-' + menuColor + '.css';
			this.replaceLink(layoutLink, layoutHref);

			const themeLink = document.getElementById('theme-css');
			const urlTokens = themeLink.getAttribute('href').split('/');
			urlTokens[urlTokens.length - 1] = 'theme-' + menuColor + '.css';
			const newURL = urlTokens.join('/');

			this.replaceLink(themeLink, newURL, () => {
				this.$appState.isNewThemeLoaded = true;
			});
		},
		onInlineMenuPositionChange(position) {
			this.inlineMenuPosition = position;
		},
		onChangeInlineMenu(e, key) {
			if(key === 'top') {
				if(this.inlineMenuBottomActive) {
					this.inlineMenuBottomActive = false;
				}
				this.inlineMenuTopActive = !this.inlineMenuTopActive;
			}
			if(key === 'bottom') {
				if(this.inlineMenuTopActive) {
					this.inlineMenuTopActive = false;
				}
				this.inlineMenuBottomActive = !this.inlineMenuBottomActive;
			}

			this.inlineMenuClick = true;
			
		},
		changeTheme(theme) {
			this.theme = theme;
			this.changeStyleSheetUrl('theme-css', theme);
		},
		onTopbarThemeChange(theme) {
			this.$emit('topbar-theme', theme);
		},
		onMenuTheme(menuTheme) {
			this.$emit('menu-theme', menuTheme);
		},
		changeStyleSheetUrl(id, value) {
			const element = document.getElementById(id);
			const urlTokens = element.getAttribute('href').split('/');
			urlTokens[urlTokens.length - 2] = value;
			const newURL = urlTokens.join('/');
			this.replaceLink(element, newURL);
		},
		replaceLink(linkElement, href, callback) {
			const id = linkElement.getAttribute('id');
			const cloneLinkElement = linkElement.cloneNode(true);

			cloneLinkElement.setAttribute('href', href);
			cloneLinkElement.setAttribute('id', id + '-clone');

			linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

			cloneLinkElement.addEventListener('load', () => {
				linkElement.remove();
				cloneLinkElement.setAttribute('id', id);

				if (callback) {
					callback();
				}
			});
		},
		blockBodyScroll() {
            if (document.body.classList) {
                document.body.classList.add('blocked-scroll');
            } else {
                document.body.className += ' blocked-scroll';
            }
        },
        unblockBodyScroll() {
            if (document.body.classList) {
                document.body.classList.remove('blocked-scroll');
            } else {
                document.body.className = document.body.className.replace(new RegExp('(^|\\b)' +
                    'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
            }
        },
		onLangChange(lang) {
			this.$emit("on-lang-change", lang)
		},
		searchMenu(items, search) {
			for (var i=0;i<items.length;i++) {
				if (items[i].idinfo == search) {
					return items[i];
				} else {
					if (items[i].items !== undefined) {
						var ret = this.searchMenu(items[i].items, search)
						if (ret != null) {
							return ret;
						}
					}
				}
			}
			return null;
		},
		searchMenuPolygon() {
			return this.searchMenu(this.menu, 'map');
		},
		checkPolygons(polygonsMenu, conceptid) {
			if (polygonsMenu.items === undefined) {
				return false;
			}
			if (polygonsMenu.items !== undefined && polygonsMenu.items != null) {
				for (var i=0;i<polygonsMenu.items.length;i++) {
					if (polygonsMenu.items[i].infoid == conceptid) {
						return true;
					}
				}
			}
			return false;
		}
    },
    computed: {
		layoutContainerClass() {
            return [
				'layout-wrapper', 
				'layout-menu-' + this.menuTheme + ' layout-topbar-' + this.topbarTheme, 
				{
					'layout-menu-static': this.menuMode === 'static',
					'layout-menu-overlay': this.menuMode === 'overlay',
					'layout-menu-overlay-active': this.overlayMenuActive,
					'layout-menu-slim': this.menuMode === 'slim',
					'layout-menu-horizontal': this.menuMode === 'horizontal',
					'layout-menu-active': this.menuActive,
					'layout-menu-mobile-active': this.mobileMenuActive,
					'layout-topbar-mobile-active': this.mobileTopbarActive,
					'layout-rightmenu-active': this.rightPanelActive,
					'layout-rtl': this.$appState.RTL,
					'p-input-filled': this.$primevue.config.inputStyle === 'filled',
					'p-ripple-disabled': this.$primevue.config.ripple === false
				}
			];
        }
    },
    components: {
        'AppTopBar': AppTopBar,
        // 'AppInlineMenu': AppInlineMenu,
        // 'AppRightPanel': AppRightPanel,
        'AppMenu': AppMenu,
        // 'AppConfig': AppConfig,
        // 'AppFooter': AppFooter,
        // 'AppBreadcrumb': AppBreadcrumb
    },
}
</script>

<style lang="scss">
@import './App.scss';
</style>
