<template>
	<div class="pages-body login-page flex flex-column">
    <div class="topbar p-3 flex justify-content-between flex-row align-items-center">
        <router-link class="topbar-left ml-3 flex" to="/">
            <div class="logo">
                <img src="assets/layout/images/logo2x.png" alt=""/>
            </div>
        </router-link>
        <router-link class="topbar-right mr-3 flex" to="/">
            <Button type="button" :label="getLabel('Dashboard')" class="p-button-text p-button-plain topbar-button"></Button>
        </router-link>
    </div>

    <div class="align-self-center mt-auto mb-auto">
        <div class="pages-panel card flex flex-column">
            <div class="pages-header px-3 py-1">
                <h2>{{ getLabel('Login') }}</h2>
            </div>

            <h4>{{ getLabel('Welcome') }}</h4>

            <div class="pages-detail mb-6 px-6">{{ getLabel('Please use the form to sign-in HunData') }}</div>

            <form>
            <div class="input-panel flex flex-column px-3">
                <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                        <i class="pi pi-envelope"></i>
                    </span>
                    <span class="p-float-label">
                        <InputText type="text" name="username" id="username" autocomplete="username" v-model="user.username" />
                        <label for="username">{{ getLabel('Email/Userame') }}</label>
                    </span>
                </div>

                <div class="p-inputgroup mt-3 mb-6">
                    <span class="p-inputgroup-addon">
                        <i class="pi pi-lock"></i>
                    </span>
                    <span class="p-float-label">
                        <InputText type="password" name="password" id="password" autocomplete="password" v-model="user.password" />
                        <label for="password">{{ getLabel('Password') }}</label>
                    </span>
                </div>

            </div>
            <Button class="login-button p-mb-6 p-px-3" :label="getLabel('Login')" @click="handleLogin()"></Button>
            </form>
        </div>
    </div>
</div>

</template>

<script>
import * as yup from "yup";
// import AuthService from '../service/AuthService';

export default {
  name: "Login",  
  components: {
  },
  data() {
    const schema = yup.object().shape({
      username: yup.string().required("Username is required!"),
      password: yup.string().required("Password is required!"),
    });
    const user = {
      username: "",
      password: "",
    }
    return {
      loading: false,
      message: "",
      schema,
      user,
    };
  },
  computed: {
    getLabelInfo(label) {
      return label;
      // return this.$t("message."+label, {}, { locale: this.$appState.locale } );
    }
    /*
    loggedIn() {
      console.log ("LI", AuthService.loggedIn())
      return AuthService.loggedIn()
    },
    */
  },
  mounted() {
    /*
    console.log ("Cre loIn", AuthService.loggedIn())
    if (AuthService.loggedIn()) {
      this.$router.push("/");
    }
    */
  },
  created() {
  },
  methods: {
    handleLogin() {
      this.loading = true;
      this.$store.dispatch("auth/login", this.user).then(
        () => {
          this.$router.push("/");
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    getLabel (label) {
				var info
				try {
					info = this.$t("message."+label, {}, { locale: this.$appState.locale } )
					if (info == "message."+label) {
						info = label;
					}
				}
				catch(err) {
					info = label;
				}
				return info;
		}  
  },
};

</script>

<style scoped>

</style>
