import { createI18n } from "vue-i18n";

const i18n = createI18n({
  legacy: false,
  locale: "en",
  globalInjection: true,
  fallbackLocale: 'en',
  formatFallbackMessages: true,
  fallbackWarn: false,
  missingWarn: false,
  silentTranslationWarn: true,
  messages: {
    en: {
      message: {
        language: "English",
        lang: "EN",
        greeting: "Hello !",
        menu: {
            Dashboard: "Dashboard",
            Search: "Search",
            Map: "Map",
            User: "User",
            Login: "Login",
            Logout: "Logout",
            Shop: "Shop",
            "Shopping Cart": "Shopping Cart",
            "My orders": "My orders",
            "Administration": "Administration",
            Users: "Users",
            Manage: "Manage",
            Groups: "Groups",
            Roles: "Roles",
            Points: "Points",
            Polygons: "Polygons",
            Lines: "Lines",
            "City Logos": "City Logos",
            Filter: "Filter",
            Other: "Other",
        },
        search: {
            DataType: "Datatype",
            Years: "Years",
            Source: "Source",
            Level: "Level",
        },
        Breadcrumb: {
            Index: "Index",
        },
        Settings: "Settings",
        "Terms of Usage": "Terms of Usage",
        Support: "Support",
        Logout: "Logout",
        Login: "Login",        
        Dashboard: "Dashboard",
        Welcome: "Welcome",
        "Please use the form to sign-in HunData": "Please use the form to sign-in HunData",
        Password: "Password",
        "Email/Username": "Email/Username",
        Goodbye: "Goodbye",
        "Back to the HunData mainpage": "Back to the HunData mainpage",
        Mainpage: "Mainpage",
        Basket: "Basket",
        "My Orders": "Rendeléseim",
        Synonyms: "Synonyms",
        Years: "Years",
        Levels: "Levels",
      }
    },
    hu: {
        message: {
            language: "Magyar",
            lang: "HU",
            greeting: "Szia !",
            menu: {
                Dashboard: "Irányítópult",
                Search: "Keresés",
                Map: "Térkép",
                User: "Felhasználó",
                Login: "Bejelentkezés",
                Logout: "Kijelentkezés",
                Shop: "Bolt",
                "Shopping Cart": "Kosár",
                "My orders": "Rendeléseim",
                "Administration": "Adminisztrálás",
                Users: "Felhasználól",
                Manage: "Kezelés",
                Groups: "Csoportok",
                Roles: "Szerepek",
                Points: "Pontok",
                Polygons: "Felületek",
                Lines: "Vonalak",
                "City Logos": "Városcímerek",
                Filter: "Szűrő",
                Other: "Egyéb",
            },
            search: {
                DataType: "Adatttípus",
                Years: "Évek",
                Source: "Forrás",
                Level: "Szint",
            },
            Breadcrumb: {
                Index: "Index",
            },
            Settings: "Beállítások",
            "Terms of Usage": "Használati feltételek",
            Support: "Támogatás",
            Logout: "Kijelentkezés",
            Login: "Bejelentkezés",
            Dashboard: "Irányítópult",
            Welcome: "Üdvözöljük",
            "Please use the form to sign-in HunData": "Kérjük, töltse ki a bejelentkezési űrlapot!",
            Password: "Jelszó",
            "Email/Username": "Email cím/Felhasználónév",
            Goodbye: "Viszontlátásra",
            "Back to the HunData mainpage": "Vissza a főoldalra",
            Mainpage: "Főoldal",
            Basket: "Kosár",
            "My Orders": "Rendeléseim",
            Synonyms: "Szinonimák",
            Years: "Évek",
            Levels: "Szintek",
        }
    },
  }
});
export default i18n;