<template>
	<div class="pages-body login-page flex flex-column">
    <div class="topbar p-3 flex justify-content-between flex-row align-items-center">
        <router-link class="topbar-left ml-3 flex" to="/">
            <div class="logo">
                <img src="assets/layout/images/logo2x.png" alt=""/>
            </div>
        </router-link>
        <router-link class="topbar-right mr-3 flex" to="/">
            <Button type="button" :label="getLabel('Dashboard')" class="p-button-text p-button-plain topbar-button"></Button>
        </router-link>
    </div>

    <div class="align-self-center mt-auto mb-auto">
        <div class="pages-panel card flex flex-column">
            <div class="pages-header px-3 py-1">
                <h2>{{ getLabel('Logout') }}</h2>
            </div>

            <h4>{{ getLabel('Goodbye') }}</h4>

            <div class="pages-detail mb-6 px-6">{{ getLabel('Back to the HunData mainpage') }}</div>

            <div class="input-panel flex flex-column px-3">

            </div>
            <Button class="login-button p-mb-6 p-px-3" :label="getLabel('Mainpage')" @click="mainPage()"></Button>
        </div>
    </div>
</div>

</template>


<script>

import AuthService from '../service/AuthService';

export default {
  name: "Logout",
  components: {
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    AuthService.logout();
    // localStorage.removeItem('user');
    // console.log(localStorage.getItem("user"))
    // this.$store.state.auth
    // alert(JSON.parse(localStorage.getItem('user')));
    // alert(JSON.parse(this.$store.state.auth.user));
    // if (this.loggedIn) {
      // this.$router.push("/profile");
     // localStorage.removeItem('user');
    // }
    this.$mySearch.search = [];
  },
    methods: {
      mainPage() {
        this.$router.push("/");
      },
     getLabel (label) {
				var info
				try {
					info = this.$t("message."+label, {}, { locale: this.$appState.locale } )
					if (info == "message."+label) {
						info = label;
					}
				}
				catch(err) {
					info = label;
				}
				return info;
		}  
    },
};

</script>

<style scoped>

</style>
