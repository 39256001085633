import {createRouter, createWebHistory} from 'vue-router';
import authHeader from './service/AuthHeader';
// import AuthService from './service/AuthService';
// import axios from 'axios';

const routes = [
    {
        path: '/',
        name: 'search',
        exact: true,
        component: () => import('./components/Search.vue'),
        props: true,
        meta: {
            breadcrumb: [{ label: 'Index' }],
        },
    },
    {
        path: '/map',
        name: 'map',
        exact: true,
        component: () => import('./components/Map.vue'),
        meta: {
            breadcrumb: [{ label: 'Map' }],
        },
    },
    {
        path: '/map-special',
        name: 'map-special',
        exact: true,
        component: () => import('./components/Map.vue'),
        meta: {
            breadcrumb: [{ parent: 'Map', label: "Special" }],
        },
    },
    {
        path: '/map-city-logos',
        name: 'map-city-logos',
        exact: true,
        component: () => import('./components/Map.vue'),
        meta: {
            breadcrumb: [{ parent: 'Map', label: "City Logos" }],
        },
    },
    {
        path: '/map-lines',
        name: 'map-lines',
        exact: true,
        component: () => import('./components/Map.vue'),
        meta: {
            breadcrumb: [{ parent: 'Map', label: 'Lines' }],
        },
    },
    {
        path: '/map-points',
        name: 'map-points',
        exact: true,
        component: () => import('./components/Map.vue'),
        meta: {
            breadcrumb: [{ parent: 'Map', label: 'Points' }],
        },
    },
    {
        path: '/map-polygons',
        name: 'map-polygons',
        exact: true,
        component: () => import('./components/Map.vue'),
        meta: {
            breadcrumb: [{ parent: 'Map', label: 'Polygons' }],
        },
    },
    {
        path: '/other',
        name: 'other',
        exact: true,
        component: () => import('./components/Map.vue'),
        meta: {
            breadcrumb: [{ label: 'Other' }],
        },
    },
    {
        path: '/shopping-cart',
        name: 'shopping-cart',
        exact: true,
        component: () => import('./pages/ShoppingCart.vue'),
        meta: {
            breadcrumb: [{ parent: "Shop", label: 'Shopping Cart' }],
        },
    },
    {
        path: '/my-orders',
        name: 'my-orders',
        exact: true,
        component: () => import('./pages/MyOrders.vue'),
        meta: {
            breadcrumb: [{ parent: "Shop", label: 'My Orders' }],
        },
    },    
    {
        path: '/users',
        name: 'users',
        exact: true,
        component: () => import('./pages/Users.vue'),
        meta: {
            breadcrumb: [{ parent: "Home", label: 'Users' }],
        },
    },
    {
        path: '/users/manage',
        name: 'manage',
        component: () => import('./pages/UsersCrud.vue'),
        meta: {
            breadcrumb: [{ parent: 'Users', label: 'Manage' }],
        },
    },
    {
        path: '/users/manage',
        name: 'manage',
        component: () => import('./pages/UsersCrud.vue'),
        meta: {
            breadcrumb: [{ parent: 'Users', label: 'Manage' }],
        },
    },
    /*{
        path: '/users/manage/adduser',
        name: 'adduser',
        component: () => import('./pages/AddUser.vue'),
        meta: {
            breadcrumb: [{ parent: 'Manage', label: 'AddUser' }],
        },
    },
    */
    {
        path: '/users/groups',
        name: 'groups',
        component: () => import('./pages/UsersGroups.vue'),
        meta: {
            breadcrumb: [{ parent: 'Users', label: 'Groups' }],
        },
    },
    {
        path: '/users/roles',
        name: 'roles',
        component: () => import('./pages/UsersRoles.vue'),
        meta: {
            breadcrumb: [{ parent: 'Users', label: 'Roles' }],
        },
    },
    {
        path: '/logout',
        name: 'logout',
        /*exact: true,*/
        component: () => import('./pages/Logout.vue'),
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./pages/Login.vue'),
    },    
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior () {
        return { left: 0, top: 0 };
    }
});

export default router;

router.beforeEach(async (to, from, next) => {
    const publicPages = ['/login', '/register', '/home', '/logout', '/error', '/', '/map', '/map-lines', '/map-points', '/map-polygons', '/map-special', '/map-city-logos',
                         'filter-points', 'filter-lines', 'filter-polygons', '/other'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('user');
 
	if (authRequired) {
        if (!loggedIn) { 
            // console.log ("-2-");
            next('/login');
        } else {
            // console.log ("Axios-start");
			if (await checkUsersToken()) {
                // console.log ("Axios-start-ok", to);
                next();
            } else {
                // console.log ("Axios-start-error-", to);
                next({ name: "login"});
            }
            // console.log ("Axios-end");
		}
	} else {
        // console.log ("-1-", to);
		next();
	}
   // console.log ("TO:", to, "FROM:", from, "NEXT:", next)
})

async function checkUsersToken() {
    const response = await fetch ('/auth/refreshToken', { headers: authHeader() } )
    // console.log ("STATUS:", response.status == 200)
    return response.status == 200
}

/*
function checkUsersToken () {
     return axios.get(, ).catch( function() { return false; }}
}
*/
